@font-face {
    font-family: 'Lato-Regular';
    src: url('./fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Light';
    src: url('./fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Thin';
    src: url('./fonts/Lato-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Bold';
    src: url('./fonts/Lato-Bold.ttf') format('truetype');
}

html {
    scroll-behavior: smooth;
}

body {
    background: #2E3A49;
    font-family: 'Lato-Light', sans-serif;
    color: hsl(0, 0%, 100%);
    font-size: 18px;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
    color: #fff;
}


h1 {
    font-weight: 275;
    font-size: 64px;
    font-family: 'Lato-Bold', sans-serif;
}

h2 {
    font-family: 'Lato-Bold', sans-serif;
}

.services-section h2 {
    font-size: 40px;
}

.faq-section h2 {
    font-size: 40px;
}

h3 {
    font-family: 'Lato-Light', sans-serif;
    font-size: 1em;
}

button {
    font-family: 'Lato-Bold', sans-serif;
}

hr {
    height: 1px;
    /* border: solid; */
    border-top: none;
    border-left: none;
    border-right: none;
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
}
.back-button {
    position: fixed;
    bottom: 0%;
    left: 1%;
    z-index: 9999;
}

.disclaimer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 1em;
    font-size: 18px;
    font-weight: 700;
    bottom: 0;
    z-index: 999;

}

.fullscreen {
    /* height: 100vh; Set the height to the full height of the viewport */
    /* background-color: #fff; */
    display: flex;
    align-items: center;
}

.about-us-content {
    z-index: 2;
    background: linear-gradient(180deg, rgba(46, 54, 64, 0.5) 0%, rgba(97, 102, 122, 0.5) 100%);
    border: 1px solid #8D8D8D;
    backdrop-filter: blur(8.5px);
    -webkit-backdrop-filter: blur(8.5px);
    border-radius: 15px;
    /* padding: 3vw; */
    /* margin: 5vw 5vw 5vw 8vw; */
    /* width: 80vw; */
    display: flex;
    flex-direction: row;
    /* gap: 32px; */
    /* position: sticky;
    top: 120px; */
    width: 50%;
    position: absolute;
    margin-left: 3%;
    padding: 2% 4% 2% 4%;
    
}

.about-us-content .swiper-slide {
    width: 100% !important;
}





.text-and-swiper {

}

.about-section .swiper-wrapper {
    height: 100vh;

}

.about-us-content img {
    border-radius: 15px;
}

.about-section .swiper {
max-width: 1920px;
}


.background-absolute-img {
    position: absolute;
    z-index: 1;
}

.salon_img {
    transform: translate(60vw, 0vw);
    border-radius: 15px;
    width: 30vw;
}

.header_img {
    /* transform: translate(-12em, -12em); */
    transform: translate(4em, -17em);
    width: auto;
    max-height: 10vw;
}

.image-with-h2 {
    position: relative;
    z-index: 1;
    margin-bottom: 5vh;
}

.image-with-h2 img {

}

.image-with-h2 h2 {
    font-size: 3.55em;
    margin-top: -72px;
}


.light-gradient {
    background: linear-gradient(90deg, #9CA9B8 0%, #2C3847 100%);
}

.border-gradient {
    border: 8px solid transparent;
    border-image: linear-gradient(90deg, #4a009f 0%, #9ba8b7 100%) 1;
    border-top: none;
    border-left: none;
    border-right: none;
    margin: 0;
}

.content {
    /* max-width: 800px; Set a maximum width for the content */
    padding: 20px 20px 0px 20px;
    box-sizing: border-box;
    text-align: left;
    display: flex;
}

.bg_images {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -999;
}

.navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 1%;
    padding-bottom: 1%;
    background: linear-gradient(180deg, #2C3847 0%, rgba(44, 56, 71, 0) 100%);
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
    position: fixed;
    width: 100%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 999;

}

.actions-section .swiper-pagination {
    display: none;
}

.usp-section .swiper-pagination {
    display: none;
}


.usp-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2% 4% 2% 4%;
    position: relative;
    flex-direction: column;
    /* max-width: 1600px; */
    margin: 0 auto;
}

.usp-section h2 {
    font-size: 3.55em;
}

.usp-swiper-container #prev {
    transform: translateY(100%);
}
.usp-swiper-container #next {
    transform: translateY(100%);
}

.usp-header-container {
    width: 100%;
    /* padding: 2% 4% 2% 4%; */
    margin: 0 auto;
    max-width: 1600px;
}
.usp-swiper-container .swiper-wrapper {
    height: 100%;
}

.actions-section .swiper-wrapper {
    height: 550px;
}

.team-swiper-container .swiper-wrapper {
    height: 70vh;
}

.team-swiper-container {
    /* max-width: 1600px; */
    margin: 0 auto;
}
.team-next-prev-buttons {
    max-width: 1600px;
}

#prev, #next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    z-index: 888;
}

.team-section #prev {
    position: absolute;
    top: 50%;
    transform: translateY(150%);
    background: none;
    border: none;
    z-index: 888;
}

.team-section #next {
    position: absolute;
    top: 50%;
    transform: translateY(150%);
    background: none;
    border: none;
    z-index: 888;
}


.toolsLeftRightArrows {
    top: 60% !important;
}


.customBullet {
    background-color: #9F009F;
}

#prev {
    left: 0;
}

#next {
    right: 0;
}


.actions-section {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 64px;
    padding: 2% 4% 5% 4%;
    /* max-width: 1600px; */
    margin: 0 auto;
}

/* .actions-section {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.usp-container {
    display: flex;
    margin: 1em 2em 1em 2em;
    padding: 20px;
    /* max-width: 80vw; */
    /* align-self: center; */
    overflow: hidden;
}

.usp-item {
    padding-left: 24px;
    min-height: 200px;
}

.usp-item h3 {
    font-size: 32px;
    font-weight: 100;
    margin-top: 0;
}

.usp-item p {
    font-size: 20px;
    margin-bottom: 0;
}

.usp-item:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* set the linear gradient */
    background: linear-gradient(0deg, #E23DB4 0%, #4601A6 100%);
    /* set the border radius */
    border-radius: 10px;
    width: 5px;
}

.tools-text-descr {
    /* padding-left: 1vw; */
    width: 100%;
    display: flex;
    flex-direction: row;
    
}

.whiteLine {
    background-color: #fff;
    height: 100%;
    width: 8px;
    min-height: 300px;
    border-radius: 25px;
}

.toolsItemInfo {
    margin-left: 8px;
}

/* .tools-text-descr li:first-child {
  font-weight: bold;
  list-style: none;
} */

.tools-header-margin {
    margin-top: 3vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    max-width: 1600px;
}

iframe body {
    background: #2e3a49 !important;
}

iframe.reviews .badge {
    background: hsl(217.5deg 15.58% 30.2%) !important;
    color: #fff !important;
}

.reviews {
    color: red;
}

/* .tools-text-descr:before {

  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  border-radius: 10px;
  width: 5px;
  height: 35%;

} */

.actions-container {
    display: flex;
    margin: 8vh 0vw 8em 0vw;
    /* padding: 20px; */
    /* max-width: 80vw; */
    /* overflow: hidden; */
    /* max-width: 1600px; */
    /* width: 90vw; */
    width: 90%;
}


.logoDiv {
    display: flex;
    /* width: 50%; */
}

.banner-logo {
    width: 100%;
}

.banner-image {
    width: 100%;
}

.links {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
}

.navigation a {
    margin-right: 2%;
    font-size: 1em;
    font-weight: 100;
}

.navigation a:hover {
    font-weight: bold;
}

.cta {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 2em;
    border-radius: 0.5em;
    cursor: pointer;
    background: linear-gradient(90deg, #9F009F 0%, #48009F 100%);
    background-color: #ffafbd;
    font-size: 1em;
    font-family: 'Lato-Regular';
    max-width: 10vw;
    white-space: nowrap;
    font-size: 1.25em;

}

.cta-relevant {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1% 2%;
    border-radius: 0.5em;
    cursor: pointer;
    background: linear-gradient(90deg, #9F009F 0%, #48009F 100%);
    background-color: #ffafbd;
    font-size: 1em;
    font-family: 'Lato-Regular';
    white-space: nowrap;
    font-size: 1em;

}

.cta:hover {
    background: linear-gradient(90deg, hsl(300, 100%, 35%) 0%, hsl(267, 100%, 35%) 100%);
    font-weight: 100;

}

.big-gradient-line {
    background: linear-gradient(90deg, #E23DB4 0%, #4601A6 100%);
    height: 10px;
    border: none;
    border-radius: 15px;
}


.price-and-link_container {
    display: flex;
    border-radius: 15px;
    /* align-items: center; */
    /* gap: 1em; */
    background-color: #2D343E;
}

/* #service-price::after {
  content: "₽";
} */

.price-container {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-radius: 15px;
}

#service-price {
    text-align: center;
    padding-left: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 2em;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: 1em;
    /* background-color: #2D343E; */
    /* transform: translateX(-30px); */
    /* z-index: -1; */
    white-space: nowrap;
    font-size: 1.25em;
}

#service-title {
    font-size: 1.5em;
}

#service-description {
    font-size: 20px;
}


.owl-item_v1 {
    background: linear-gradient(90deg, #9CA9B8 0%, #2C3847 100%);
    height: 100px;
    width: 50px;
    margin: 1%;
}

.procedures-title {
    font-size: 1.25em;
}

#prev {
    background-color: rgb(255, 255, 255, 0);
    border: none;
    cursor: pointer;
}

#prev img {
    max-width: 3vw;
}

#next {
    background-color: rgb(255, 255, 255, 0);
    border: none;
    cursor: pointer;
}

.nextButton.previousButon {
    background-color: rgb(255, 255, 255, 0);
    border: none;
    cursor: pointer;
}

.previousButon {
    background-color: rgb(255, 255, 255, 0);
    border: none;
    cursor: pointer;
}

#next img {
    max-width: 3vw;
}

#prev-actions {
    background-color: rgb(255, 255, 255, 0);
    border: none;
    cursor: pointer;
}

#prev-actions img {
    max-width: 3vw;
}

#next-actions {
    background-color: rgb(255, 255, 255, 0);
    border: none;
    cursor: pointer;
}

#next-actions img {
    max-width: 3vw;

}

#next-tools {
    background-color: rgb(255, 255, 255, 0);
    border: none;
    cursor: pointer;
}

#prev-tools {
    background-color: rgb(255, 255, 255, 0);
    border: none;
    cursor: pointer;
}

#next-tools img {
    max-width: 3vw;

}

#prev-tools img {
    max-width: 3vw;
}


.action-block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    gap: 1em;

}

.action-block h2 {
    margin: 0 auto;
}

.action-block p {
    font-size: 1.25em;
}

.action h4 {
    font-size: 1.5em;
    margin: 0;
}


.action p {
    font-size: 20px;
}

.actions-section .action_icon {
    width: 256px;
}

.left-part {
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/* .tabcontent {
  float: left;
  padding: 0px 12px;
  border: 1px solid #ccc;
  width: 70%;
  border-left: none;
  height: 300px;
  transition: all 300ms;
} */

.tablinks {
    cursor: pointer;
    font-family: 'Lato-Bold';
}

/* .tablinks:hover {
    color: #ae8eb4;
} */

.services {
    margin: 1em 1em 1em 1em;
    display: grid;
    /* gap: 3em; */
    grid-template-areas: "descr image";
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;

}

.services-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    flex-grow: 1;
    grid-area: descr;
}

.services-image {
    /* width: 100%; */
    flex-grow: 1;
    grid-area: image;
}

.services_of_category {
    list-style: none;
}

.service-item {
    cursor: pointer;
    background-color: hsl(213 23% 29% / 1);
    border-radius: 10px;
    padding: 0.5em;
    margin-bottom: 1em;
    transition: all 300ms;
    box-sizing: border-box;
    font-size: 20px;
}

.warning {
    background-color: rgb(255 111 111 / 30%);
    padding: 1%;
    border-radius: 15px;
    border: 1px solid;
}

.recommendation {
    background-color: rgb(111 255 111 / 30%);
    padding: 1%;
    border-radius: 15px;
    border: 1px solid;
    width: -webkit-fill-available;

}

.advice {
    background-color: rgba(248, 255, 156, 0.3);
    padding: 1%;
    border-radius: 15px;
    border: 1px solid;
    width: -webkit-fill-available;


}

.service-item:hover {
    background-color: hsl(213 23% 32% / 1);
}

.active {
    background-color: hsl(213 23% 29% / 1);
    border: 1px solid #E23DB4;

}

.navActive {
    background-color: hsl(213 23% 29% / 1);
    border: 1px solid #E23DB4;
}

.category_header {
    cursor: pointer;
    font-weight: 100;
    font-size: 24px;
    margin: 8px;
}

.category_header:hover {
    color: #eeeeee;
}


.services-image img {
    border-radius: 15px;
    /* max-width: 100%;
    max-width: 440px; */
    width: 100%;
}

#service-results {
    /* list-style: none; */
    padding: 0;
}

#service-results h4 {
    font-size: 1.5em;
    font-weight: 100;
}

#service-results li {
    display: flex;
    align-items: center;
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 0.5em;
    /* list-style-image: url(./img/list-marker.svg); */
    background-color: rgb(255 255 255 / 10%);
    padding: 2%;
    border-radius: 15px;
}

#service-results li:before {
    /* content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 5px;
 background-image: url(./img/list-marker.svg);
  margin-right: 10px; */
    /* content: url(/img/list-marker.svg); */
    height: 18px;
    width: 16px;
    vertical-align: middle;
    margin-right: 1vw;
}

.detail_page_url {
    /* text-decoration: revert; */
    margin: 1vw 0vw 0vw 0vw;
    font-size: 1em;
    /* border: 1px solid; */
    border-radius: 10px;
    /* padding: 1vw;*/
    border: 1px solid;
    padding: 15px;
    /* width: 83%; */
    text-align: center;

}

.tools-controls {
    position: absolute;
    width: 100%;
    top: 350px;
    display: flex;
    justify-content: space-between;
    z-index: 2;
}


.percent {
    position: absolute;
    margin: -7em auto;
    max-width: 800px;
    width: 50em;
    left: 0;
    right: 0;
}

.actions {
    display: flex;
    gap: 15px;

}

.action {
    border-radius: 15px;
    background: linear-gradient(180deg, rgba(46, 54, 64, 0.5) 0%, rgba(97, 102, 122, 0.5) 100%);
    border: 1px solid #8D8D8D;
    backdrop-filter: blur(8.5px);
    -webkit-backdrop-filter: blur(8.5px);
    /* border-radius: 25px; */
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    /* width: 250px; */
    padding: 2em 1em 1em 1em;
    display: flex;
    flex-direction: column;
    gap: 2em;
    min-height: 25vw;
    justify-content: space-between;
}

.action_icon {
    max-width: 420px !important;
}

.catalog-container {
    display: grid;
    /* justify-content: center; */
    grid-template-areas: "nav content";
    grid-template-columns: 1fr 3fr;
    grid-gap: 1em;

}

.catalog-navigation {
    max-height: 600px;
    overflow: auto;
    /* max-width: 400px; */
    padding: 0em 1em 0em 0em;
    grid-area: nav;
    position: sticky;
    top: 110px;
}

.cta_breaker {
    display: flex;
    background: linear-gradient(90deg, #9F009F 0%, #48009F 100%);
    box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.25), inset 2px 0px 4px rgba(105, 105, 105, 0.25);
    padding: 2% 4% 2% 4%;
}

.cta_breaker-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    max-width: 1600px;
    margin: 0 auto;
}

.cta_breaker-content h2 {
    font-size: 2.5em;
}

.cta_breaker-content p {
    font-size: 1.25em;
}

.cta_breaker-content-text {
    color: #fff;

}

.cta_white {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 2em;
    border-radius: 0.5em;
    /* cursor: pointer; */
    background-color: #ffffff;
    font-size: 1em;
    font-family: 'Lato-Regular';
    width: 160px;
    height: 24px;
    color: #9F009F;
}


/* AccordeonNav */
.accordion {
    background-color: rgb(238, 238, 238, 0);
    color: #ffffff;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion img {
    transition: all 0.4s;
}

/* .accordion::after {
    content: url("../public/img/icons/faq-arrow.png");
    font-size: 20px;
    color: #ffffff;
    float: right;
    margin-left: 5px;

} */

.accordionContainer {
    display: flex;
    flex-direction: column;
    background-color: #374658;
    border-radius: 15px;
    margin-bottom: 1%;

}

.relevant-item {
    display: flex;
    width: 100%;
    /* align-content: space-between; */
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #374658;
    padding: 1% 2%;
    border-radius: 15px;
    align-items: center;
}


.active, .accordion:hover {
    background-color: rgb(204, 204, 204, 0);
}

.panel {
    padding: 0 18px;
    /* background-color: white; */
    /* max-height: 0; */
    /* overflow: hidden; */
    transition: max-height 0.2s ease-out;
    display: flex;
    /* flex-direction: column; */
    /* width: 100%; */
    flex-direction: column;
    /* height: 200px; */
    padding-bottom: 15px;
}


.catalog_navigation_mobile {
    /* display: none; */
    flex-direction: column;
    margin-bottom: 1em;
}

/*YANDEX MAP*/
#map {
    height: 500px;
    width: 60%;
    /* border-radius: 15px; */
    background-color: #2E3A49;


}

.ymaps-2-1-79-ground-pane {
    filter: saturate(0.3) invert(1) hue-rotate(303deg);
}
.ymaps-2-1-79-balloon .ymaps-2-1-79-balloon_layout_normal .ymaps-2-1-79-balloon_to_top .ymaps-2-1-79-i-custom-scroll {
    background-color: #2E3A49 !important; 
    color: #fff;
}

#map > ymaps {
    border-radius: 15px;
    overflow: hidden;
}

/* .ymaps-dark {
    background-color: #000;
    color: #fff;
} */


.ymaps-2-1-79-cluster__icon,
.ymaps-2-1-79-image {
    filter: none;
}


select {
    font-size: 1em;
    background: none;
    color: #fff;
    border-radius: 15px;
    padding: 0.75em;
}

select:focus {
    outline: none;
}

option {
    background-color: #2e3a49;
    padding: 1em;
    margin: 1em;
}

option:hover {
    background-color: hsl(213 23% 32% / 1);
}

.services_scroll {

    justify-content: flex-start;
    gap: 1em;
    align-items: center;
    max-height: 25vh;
    flex-direction: column;
    overflow: auto;
    margin-top: 1em;
    display: none;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Background color of scrollbar */
::-webkit-scrollbar-track {
    background-color: #161b22;
}

/* Color of scrollbar handle */
::-webkit-scrollbar-thumb {
    background-color: #9ca9b8;
    border-radius: 15px;

}

/* For WebKit-based browsers */
/* Width of scrollbar */
html::-webkit-scrollbar {
    width: 10px;
}

/* Background color of scrollbar */
html::-webkit-scrollbar-track {
    background-color: #161b22;
}

/* Color of scrollbar handle */
html::-webkit-scrollbar-thumb {
    background-color: #9ca9b8;
}

#sliding-div {
    /* position: sticky; */
    height: fit-content;
    position: -webkit-sticky;
    top: 0;
    /* padding: 8em 0em 0em 0em; */
}

#all_services {
    /* padding-left: 1em;
    padding-right: 1em; */
    padding: 3em;
    margin: 0 auto;
    max-width: 1600px;
}

#all_services h2 {
    /* margin-bottom: 8vw; */
}

.team-header {
    margin: 0 auto;
    max-width: 1600px;
    padding: 2% 4% 2% 4%;
}

.unfold {
    font-size: 1.5em;
}

.usp-swiper-container {
    width: 100%;
    /* position: relative; */
}

.usp-swiper-container .swiper {
    max-width: 1600px;
    width: 90%;
}

.actions-section .swiper {
    max-width: 1600px;
    width: 90%;
}

/*MEET -------------------------------------------------------------------THE TEAM TESTS*/


.carousel-item {
    display: flex;
    min-height: 600px;
    width: 500px;
    font-size: 1.15em;
}


.indicators {
    display: flex;
    position: relative;
    /* transform: translateY(670px); */
    justify-content: center;
    width: 4em;
    gap: 1em;
}

.master-description {
    border: 1px solid #8D8D8D;
    border-radius: 15px;
    padding: 1em 1em 1em 1em;
    background: linear-gradient(180deg, rgba(46, 54, 64, 0.5) 0%, rgba(97, 102, 122, 0.5) 100%);
    border: 1px solid #8D8D8D;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 25px;
    z-index: -1;
    /* display: none; */

}

.carousel-team .carousel-item:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.carousel-team .carousel-item img {
    width: 100%;
}

.carousel-team .carousel-item h3 {
    /* background-color: #ffffff;
    color: #000000;
    font-size: 2em;
    font-weight: bold;
    margin: -5px 0 0;
    padding: 10px 5px;
    text-align: center; */
    width: 10em;
}

/*END--------------------------------------------------------------------- MEET THE TEAM*/

.contacts-block {
    display: flex;
    width: 100%;
    gap: 1%;

}

.contats {
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 2%;
    /* margin: 0em 3em 0em 3em; */
}

.contats h1 {
    margin-top: 0px;
    
}

.address, .phone {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1% 2%;
    border-radius: 15px;
    /* width: 100%; */
}

.address-phone {
    /* display: flex; */
    gap: 1em;
}
.addressonMap a {
    color:#161b22 !important;
    text-decoration: underline;
}

.spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 9px solid;
    border-color: #4a009f;
    border-right-color: #98009f;
    animation: spinner-d3wgkg 1s infinite linear;
    position: relative;
    /* top: 14em; */
    /* left: 34em; */
    margin: 15em auto;
    /* margin-top: 15em; */
}

.preloader {
    position: absolute;
    border-radius: 1em;
    z-index: 999;
    filter: drop-shadow(0px 0px 17px black);

    left: 60vw;
}

.team-section {
    padding: 2% 0% 4% 0%;
    height: auto;
    position: relative;
}

.team-section h3 {
    font-size: 36px;
    margin: 16px 0 16px 0;
}

.team-section p {
    margin: 0;
}

.team-section .swiper {
    overflow: hidden;
}

.tools-section {
    display: flex;
    flex-direction: column;
    /* max-width: 1600px; */
    margin: 0 auto;
    padding: 2% 4% 2% 4%;
    position: relative;
}

.tool-item img {
    width: 100%;
    border-radius: 15px;
} 

.map-section {
    margin: 3em;
    display: flex;
    gap: 1vw;
    max-width: 1600px;
    margin: 0 auto;
    border-radius: 15px;
    overflow: hidden;
}

.widget-646cab85c5b33598f7f8d848 {
    max-width: 1600px !important;
}


.tools-img-header {
    max-height: 300px;
    max-width: 90vw;
    z-index: -1;
}

.reviews-img-header {
    max-height: 300px;
    max-width: 40vw;
}

.tools-header-descr {
    width: 30vw;
}

.tool-item {
    display: flex;
    flex-direction: column;
    padding: 1em;
    /* align-items: center; */
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.tools-container {
    max-width: calc(min(1584px, 90%));
    overflow: hidden;
}

.tool-item img {
    /* width: 100%; */
    /* max-width: 320px; */
}

.tool-item h3 {
    font-size: 1.25em;
    font-weight: bold;
}

.tool-item ul {
    font-size: 1.25em;
}


.foot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(44, 56, 71, 0) 0%, #1A1A1A 100%);
    text-align: center;
    margin-top: 5em;
    padding-bottom: 1em;
}

.footer-nav {
    display: flex;
    flex-direction: row;
    gap: 1em;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-nav a:hover {
    filter: drop-shadow(0px 0px 1px white);
}

.yandex-badges {
    display: flex;
    justify-content: center;
    gap: 1em;
}

#about {
    font-size: 3em;
    margin: 1vw auto;
}



.about-us-text p {
    font-size: 1.25em;
}

.about-section .swiper-pagination-bullet-active {
    background: #E23DB4;
}

.footer-content {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.socials-icons {
    display: flex;
    justify-content: center;
    gap: 1em;
}

.icon {
    width: 50px; /* set the width and height of your icon */
    height: 50px;
    transition: all 0.5s ease-in-out; /* add transition effect for smooth animation */
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.icon img {
    position: absolute;
    top: -67px;
    left: -10px;
    transition: all 300ms;
}

.icon img:hover {
    top: -17px;
    left: -10px;
}

.insta {
    transition: all 0.5s ease-in-out;
    width: 50px;
    height: 50px;
    /* background-image: url('../img/icons/instagram.svg'); */
    background-size: 217%;
    background-position: -28px -105px;
    
}

.insta:hover {
    background-position: -28px -28px;
}

.whatsapp {
    transition: all 0.5s ease-in-out;
    width: 50px;
    height: 50px;
    /* background-image: url("/img/icons/whatsapp.svg"); */
    background-size: 217%;
    background-position: -28px -105px;
}

.whatsapp:hover {
    background-position: -28px -28px;
}

.telegram {
    transition: all 0.5s ease-in-out;
    width: 50px;
    height: 50px;
    /* background-image: url("/img/icons/telegram.svg"); */
    background-size: 217%;
    background-position: -28px -108px;
}

.telegram:hover {
    background-position: -28px -28px;
}

.category-title-container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.rotate {
    transform: rotate(45deg);
}

.tools-wrapper {
    display: flex;
    flex-direction: row;
    /* max-width: 1600px; */
    align-self: center;
    position: relative;
    width: 100%;
    justify-content: center;
}

/*SWIPER ------------------------------------------------------------------- TEST*/
.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
    transition: transform 0.3s;
}

.team-section .swiper-slide img {
    display: block;
    /* width: 100%; */
    position: absolute;
    max-height: 600px;
    left: -230px;
    z-index: 999;
    transition: transform 1s;
    
}

.team-section .swiper-slide-active img:hover {
    transform: translateX(-100px);
    
}

.swiper-slide img {
    display: block;
    /* width: 100%; */
    
    
}

.mySwiper-pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.swiper-button-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  } 

  .banner-image {
    mix-blend-mode: overlay;
    z-index: -999;
}

.category-container__folded {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease-out;
}

.category-container__unfolded {
    max-height: 1000px; /* Задайте максимальную высоту, которая больше, чем ожидаемая высота контента */
    overflow: hidden;
    transition: max-height 1s ease-in;
}





.swiper-slide .master-description {
    display: none;
}


.swiper-slide-active .master-description {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 110px;
    z-index: 9;
}

.team-section .swiper-slide.swiper-slide-next {
    transform: scale(0.8);
    opacity: 0.5;
}
.team-section .swiper-slide.swiper-slide-prev {
    transform: scale(0.8);
    opacity: 0.5;
}

/*END SWIPER ------------------------------------------------------------------- TEST*/

.controls {
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    /* position: absolute; */
    transform: translateY(-400px);
}

.actions-controls {
    position: absolute;
    width: 100%;
    top: 683px;
}



.controls img {
    max-width: 3vw;
}

.reviews-section {
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    margin: 0 auto;
    padding: 2% 4% 2% 4%;
}

.custom-balloon {
    border-radius: 15px;
    background: #fff;
    color: #000;
}

.custom-balloon::before {

}

/* The Overlay (background) */
.overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100vh;
    width: 0;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0); /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
    z-index: 999;
}

/* Position the content inside the overlay */
.overlay-content {
    position: relative;
    top: 25%; /* 25% from the top */
    width: 100%; /* 100% width */
    text-align: center; /* Centered text/links */
    margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* The navigation links inside the overlay */
.overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 2em;
    color: #ffffff;
    display: block; /* Display block instead of inline */
    transition: 0.3s; /* Transition effects on hover (color) */
}

/* When you mouse over the navigation links, change their color */
.overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
}

.burger {
    font-size: 32px;
    margin-right: 3vw;
    display: none;
}

/*Service styles*/

.services-section {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
    margin-bottom: 1%;
}

.services-video-descr-img {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.service-video-descr {
    display: flex;
    flex-direction: column;
}

.service-h1 {
}

.service-image-containter {
    position: sticky;
    top: 100px;

}

.service-image-containter img {
    border-radius: 15px;
    max-width: 303px;
    height: auto;
}

.service-description {

}

.service-youtube-video iframe {
    border-radius: 15px;
}

/* Style the tab */
.tab {
    overflow: hidden;
    margin-bottom: 16px;
}

/* Style the buttons that are used to open the tab content */
.tab button {
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    font-size: 40px;
    color: #738496;
    background-color: rgb(255 255 255 / 0%);
}

/* Change background color of buttons on hover */
.tab button:hover {
    color: hsl(211, 14%, 57%);
}

/* Create an active/current tablink class */
.tab button.active {
    color: #ffffff;
}

/* Style the tab content */
.tabcontent {
    display: none;
    border-top: none;
    width: 100%;
    flex-direction: column;
    gap: 16px;
    border-radius: 15px;
    background: #374658;
    padding: 16px;

}

.padding-for-nav {
    padding-top: 100px;
}
.padding-for-main-banner {
    padding-top: 50px;
}

.about-content-wrapper {
    width: 100%;
    display: flex;
    gap: 64px;
    padding: 10%;
}

.about-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    width: 100%;
    position: relative;
}

.about-item img {
    position: absolute;
    width: 100%;
    /* opacity: 80%; */
}


.tabcontent_1 {
    display: none;
    border-top: none;
    width: 100%;
    flex-direction: column;
    gap: 16px;
}

.activeTab {
    display: flex;
}

.tabs-item {
    /* display: flex; */
    /* padding: 16px; */
    flex-direction: column;
    align-items: flex-start;
    /* gap: 16px; */
    align-self: stretch;
    /* border-radius: 15px; */
    /* background: #374658; */
    margin-bottom: 1%;
}

.tabs-item h3 {
    margin: 0;
    font-size: 20px;
}

.faq-section {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
    margin-bottom: 1%;
}
.faq-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

}
.faq-container h3 {
    font-size: 2.2em;
    font-weight: 700;

}

.tabs-item p {
    margin: 0;
}

.image-tabs {
    display: flex;
    gap: 32px;
}

.tabs-item-results {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.beforeafter_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.beforeafter_images {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@keyframes spinner-d3wgkg {
    to {
        transform: rotate(1turn);
    }
}

@media only screen and (max-width: 1132px) {
    .action-block {
        flex-direction: column;
        align-items: center;
        gap: 0em;
    }


    .catalog-navigation {
        /* display: none; */
        position: relative;
        top: 0;
    }

    .links {
        display: none;
    }

    .catalog_navigation_mobile {
        display: flex;
        gap: 1em;
    }

    .catalog-container {
        display: flex;
        flex-direction: column;
    
    }

    .services {
        display: grid;
    }

    .services_scroll {
        display: flex;
    }

    .service-item {
        margin-top: 0em;
        margin-bottom: 0em;
        font-size: 1em;
        margin-bottom: 16px;
    }

    .image-with-h2 img {
        display: none;
        position: relative;
        z-index: 3;
    }

    .tools-header-descr {
        width: auto;
        margin: 3%;
    }

    .burger {
        display: block;
    }

}

@media only screen and (max-width: 797px) {
    .banner-logo {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .about-us-text p {
        font-size: 16px;
    }

    .carousel-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        
    }
    .team-section .swiper-slide img {
        position: relative;
        left: 0;
        max-height: 250px;
        transform: translateY(50px);
    }

    .team-section .swiper-slide-active img:hover {
        transform: none;
        
    }

    .actions-section .swiper-pagination {
        display: block;
    }

    .actions-section .swiper-pagination-bullet-active {
        background: #E23DB4;
    }

    .usp-section .swiper-pagination {
        display: block;
    }

    .usp-section .swiper-pagination-bullet-active {
        background: #E23DB4;
    }

    .usp-item {
        padding-bottom: 32px;
    }
    

    .swiper-slide-active .master-description {
        display: flex;
    flex-direction: column;
    width: auto;
    margin-left: 0px;
    z-index: 9;
    font-size: 18px;
    }

    .price-and-link_container {
        flex-direction: column;
    }

    .content {
        flex-direction: column-reverse;
    }


    .big-logo-mob {
        transform: translateY(-15vw);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    }

    .salon_img {
        /* transform: translate(20vw, -10vh); */
        border-radius: 15px;
        width: 60vw;
    }

    .header_img {
        transform: translate(0vw, -50vw);
        width: 53vw;
    }

    .about-us-content {
        margin: 32px 16px 32px 16px;
        width: 100%;
        flex-direction: column;
        position: relative;
    }
    .about-section .header_img {
        display: none;
        transform: none;
    }

    .about-section .swiper {
        width: 100%;
        border-radius: 15px;
        display: none;
    }

    .cta_breaker-content {
        flex-direction: column;
        text-align: center;
    }

    .cta_breaker {
        padding: 8% 4% 8% 4%;
    }

    .services {
        display: flex;
    }

    .services-video-descr-img {
        flex-direction: column;
    }

    .image-tabs {
        flex-direction: column;
    }
    
    .usp-header-container h2 {
        font-size: 42px;
    }

    .tab {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: sticky;
        top: 0;
        background-color: #2E3A49;
    }

    .services-section {
        margin: 0em 1em 0em 1em;
        align-items: center;
        justify-content: center;

    }

    .service-image-containter {
        margin: 0 auto;
        position: relative;
        top: 0px;
    }

    .tools-section {
        padding: 0em;
        
    }

    .map-section {
        margin: 0em;
        flex-direction: column;
        margin: 0 7% 0 7%;
    }

    .image-with-h2 h2 {
        font-size: 10vw;
        margin: 0;
        padding: 3vw 0vw 0vw 3vw;
    }

    .image-with-h2 {
        /* padding: 1em 0em 0em 1em; */
    }

    .cta {
        max-width: none;
    }


    #sliding-div {
        padding: 0em 0em 0em 0em;
    }

    .preloader {
        position: fixed;
        width: 100%;
        height: 100vh;
        background-color: rgb(0 0 0 / 20%);
        top: 0;
        left: 0;
    }

    .contacts-block {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        /* margin: 3em; */
        gap: 1%;


    }

    .address-phone {
        flex-direction: column;
        margin: 3%;
    }

    #map {
        height: 300px;
        width: 100%;
        border-radius: 15px;
    }

    .contats {
        width: 100%;
    }

    .percent {
        width: 20em;
    }

    #all_services {
        padding-left: 1em;
        padding-right: 1em;
    }

    .services {
        margin: 0em;
    }

    .carousel-item img {
        /* transform: translateX(-60px); */
    }

    .team-section {
        padding: 2% 4% 8% 4%;
    }


    /* .master-description {
        padding: 1em 1em 1em 1em;
        transform: translateX(-20px);
        padding-left: 75px;

    } */

    #next {
        display: none;
    }

    #prev {
        display: none;
    }

    #next-tools {
        display: none;
    }

    #prev-tools {
        display: none;
    }

    #next-actions {
        display: none;
    }

    #prev-actions {
        display: none;
    }

    .team-swiper-container .swiper-wrapper {
height: 90vh;
    }

    .about-section {
        padding: 5vw 5vw 10vw 5vw;
        flex-direction: column;
    }

    .carousel-team {
        height: 550px;
    }

    .carousel-team .carousel-item {
        height: 400px;
        width: 166px;

    }

    .controls {
        display: none;
    }

    .detail_page_url {
        text-align: center;
        margin: 5vw 0vw 0vw 0vw;
    }

    .overlay a {
        font-size: 20px
    }

    .overlay .closebtn {
        font-size: 40px;
        top: 15px;
        right: 35px;
    }

    .relevant-item {
        flex-direction: column;
        padding: 4% 2% 4% 2%;
    }

    .cta-relevant {
        padding: 1em 2em;
    }

}

